import css from '@utils/css'
import useMods from '@hooks/useMods'
import PropTypes from 'prop-types'
import React from 'react'

const NavbarToggler = ({ children, mods, onClick, className: classNameTail }) => {
    const { className } = useMods(NavbarToggler, { mods }, true)

    return (
        <button
            type='button'
            className={ css(className, classNameTail) }
            onClick={ onClick }
            role='button'
            aria-label='Toggle navbar'
        >
            { children }
        </button>
    )
}

NavbarToggler.displayName = 'Navbar-Toggler'

NavbarToggler.propTypes = {
    children: PropTypes.node,
    mods: PropTypes.object,
    onClick: PropTypes.func
}

NavbarToggler.defaultProps = {
    mods: {}
}

export default NavbarToggler
