import useMods from '@hooks/useMods';
import PropTypes from 'prop-types';
import React from 'react';

const NavbarSubnav = ({ children, mods, mix }) => {
    const { className } = useMods(NavbarSubnav, { mix, mods }, true);

    return (
        <div
            className={ className }
        >
            { children }
        </div>
    );
};

NavbarSubnav.displayName = 'Navbar-Subnav';

NavbarSubnav.propTypes = {
    children: PropTypes.node,
    mix: PropTypes.object,
    mods: PropTypes.object,
    onMouseEnter: PropTypes.func,
    onMouseLeave: PropTypes.func
};

NavbarSubnav.defaultProps = {
    mix: {},
    mods: {}
};

export default NavbarSubnav;
