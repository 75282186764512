import useMods from '@hooks/useMods';
import isBoolean from 'lodash/isBoolean';
import Link from 'next/link';
import PropTypes from 'prop-types';
import React from 'react';

const ListItem = ({ children, mods, mix, action, href, className: classNameTail }) => {
    const { className } = useMods(ListItem, { mix, mods: { ...mods, action } });

    return (
        action ? (
            <Link
                href={ href }
                prefetch={ false }
            >
                { /* eslint-disable-next-line jsx-a11y/anchor-is-valid */ }
                <a
                    className={ className }
                >
                    { children }
                </a>
            </Link>
        ) : (
            <li
                className={ className + (classNameTail ? ' ' + classNameTail : '') }
            >
                { children }
            </li>
        )
    );
};

ListItem.displayName = 'List__Item';

ListItem.propTypes = {
    action: function(props, propName, componentName) {
        if(props.action !== undefined && !isBoolean(props.action)) {
            return new Error(
                'The prop `' + propName + '` is marked as required in' + ' `' + componentName + '`, but its value is `undefined`'
            );
        }
        if(props.to !== undefined && props.action === undefined) {
            return new Error(
                'The prop `' + propName + '` is marked as required with the prop `to` in' + ' `' + componentName + '` but its value is `undefined`'
            );
        }
    },
    children: PropTypes.node,
    mix: PropTypes.object,
    mods: PropTypes.object,
    href: PropTypes.string
};

ListItem.defaultProps = {
    mix: {},
    mods: {}
};

export default ListItem;
