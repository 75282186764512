import css from '@utils/css'
import useMods from '@hooks/useMods';
import PropTypes from 'prop-types';
import React from 'react';

const Navbar = ({ children, mods, mix, className: classNameTail }) => {
    const { className } = useMods(Navbar, { mix, mods }, true);

    return (
        <nav
            className={ css(className, classNameTail) }
        >
            { children }
        </nav>
    );
};

Navbar.displayName = 'Navbar';

Navbar.propTypes = {
    children: PropTypes.node,
    mix: PropTypes.object,
    mods: PropTypes.object
};

Navbar.defaultProps = {
    mix: {},
    mods: {}
};

export default Navbar;
