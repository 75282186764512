import useMods from '@hooks/useMods';
import PropTypes from 'prop-types';
import React from 'react';

const NavbarCollapseHeader = ({ children, mods, mix }) => {
    const { className } = useMods(NavbarCollapseHeader, { mix, mods }, true);

    return (
        <div
            className={ className }
        >
            { children }
        </div>
    );
};

NavbarCollapseHeader.displayName = 'Navbar-Collapse-Header';

NavbarCollapseHeader.propTypes = {
    children: PropTypes.node,
    mix: PropTypes.object,
    mods: PropTypes.object
};

NavbarCollapseHeader.defaultProps = {
    mix: {},
    mods: {}
};

export default NavbarCollapseHeader;
