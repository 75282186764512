import atomCollapsed from '@components/Navbar/atoms/Collapsed';
import useMods from '@hooks/useMods';

import PropTypes from 'prop-types';
import React, { useEffect, useRef } from 'react';
import { useRecoilState } from 'recoil';

const NavbarCollapse = ({ children, mods, mix }) => {
    const { className } = useMods(NavbarCollapse, { mix, mods }, true);

    // const collapsed = useRecoilValue(atomCollapsed);
    const [collapsed, setCollapsed] = useRecoilState(atomCollapsed)
    let ref = useRef();

    const handleOutsideClick = (e) => {
        if (ref.current && !ref.current.contains(e.target)) {
            setCollapsed(false);
        }
    };

    useEffect(() => {
        document.body.addEventListener('click', handleOutsideClick);

        return () => {
            document.body.removeEventListener('click', handleOutsideClick);
        };
    }, []);

    return (
        <div
            ref={ ref }
            className={ className + (collapsed ? ' collapsed' : '') }
        >{ children }
        </div>
    );
};

NavbarCollapse.displayName = 'Navbar-Collapse';

NavbarCollapse.propTypes = {
    children: PropTypes.node,
    mix: PropTypes.object,
    mods: PropTypes.object
};

NavbarCollapse.defaultProps = {
    mix: {},
    mods: {}
};

export default NavbarCollapse;
