import useMods from '@hooks/useMods';
import PropTypes from 'prop-types';
import React from 'react';

const List = ({ children, mods, mix, gap, tag, className: classNameTail }) => {
    const { className } = useMods(List, { mix, mods: { ...mods, gap } });
    const TagName = tag;

    return (
        <TagName
            className={ className + (classNameTail ? ' ' + classNameTail : '') }
        >
            { children }
        </TagName>
    );
};

List.displayName = 'List';

List.propTypes = {
    children: PropTypes.node,
    gap: PropTypes.number,
    mix: PropTypes.object,
    mods: PropTypes.object,
    tag: PropTypes.string
};

List.defaultProps = {
    mix: {},
    mods: {},
    tag: 'ul'
};

export default List;
