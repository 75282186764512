import useMods from '@hooks/useMods'
import Link from 'next/link'
import PropTypes from 'prop-types'
import React from 'react'

const NavbarBrand = ({ children, mods, href, title }) => {
    const { className } = useMods(NavbarBrand, { mods }, true)

    return (
        <Link
            href={ href }
            title={ title }
            prefetch={ false }
        >
            { /* eslint-disable-next-line jsx-a11y/anchor-is-valid */ }
            <a
                className={ className }
            >
                { children }
            </a>
        </Link>
    )
}

NavbarBrand.displayName = 'Navbar-Brand'

NavbarBrand.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    href: PropTypes.string.isRequired,
    mods: PropTypes.object,
    title: PropTypes.string
}

NavbarBrand.defaultProps = {
    mods: {}
}

export default NavbarBrand
