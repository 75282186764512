import useMods from '@hooks/useMods';
import PropTypes from 'prop-types';
import React from 'react';

const NavbarNav = ({ children, mods }) => {
    const { className } = useMods(NavbarNav, { mods }, true);

    return (
        <ul
            className={ className }
        >
            { children }
        </ul>
    );
};

NavbarNav.displayName = 'Navbar-Nav';

NavbarNav.propTypes = {
    children: PropTypes.node,
    mods: PropTypes.object
};

NavbarNav.defaultProps = {
    mods: {}
};

export default NavbarNav;
