import useMods from '@hooks/useMods';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';

const Portal = ({ mods, children }) => {

    const { className } = useMods(Portal, { mods });

    const [mounted, setMounted] = useState(false);

    useEffect(() => {
        setMounted(true);

        return () => setMounted(false);
    }, []);

    return mounted && createPortal(<div className={ className }>{ children }</div>, document.querySelector('#portal'));
};

Portal.displayName = 'Portal';

Portal.propTypes = {
    mods: PropTypes.object,
    children: PropTypes.node
};

Portal.defaultProps = {
    mods: {}
};

export default Portal;
