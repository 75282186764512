import { useEffect, useRef } from 'react';

const useEventHandler = (eventName, eventHandler, eventTarget) => {
    const eventHandlerRef = useRef();

    useEffect(() => {
        eventHandlerRef.current = eventHandler;
    });

    useEffect(() => {
        if (!eventTarget) {
            eventTarget = window;
        }

        const handleEvent = (...args) => eventHandlerRef?.current(...args);

        eventTarget.addEventListener(eventName, handleEvent);

        return (() => eventTarget.removeEventListener(eventName, handleEvent));
    }, []);
};

export default useEventHandler;
